<template>
  <q-card class="card text-center" flat>
    <q-card-section>
      <img src="../assets/logo.svg" />
      <h4> {{title}} </h4>
    </q-card-section>
    <q-card-section>
      <h5>
        {{ content }}
      </h5>
      <br>
      <br>
      <div class="row justify-start items-center">
        <q-btn class="submitLogin full-width text-white" rounded type="submit" size="lg" @click="navTo">
          Continue
        </q-btn>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import Api from '../utils/api'

export default {
  meta: {
    title: 'Continue To Login'
  },
  data () {
    return {
      identifier: this.$route.query.identifier
    }
  },
  computed: {
    isReset () {
      return this.$route.name === 'reset'
    },
    title () {
      return this.isReset ? 'Password reset' : 'Thank you for registering'
    },
    content () {
      return this.isReset ? 'Your password has been reset successfully.' : 'Your account has been successfully created.'
    }
  },
  mounted () {
    if (!this.isReset) {
      this.verifiedUser()
    }
  },
  methods: {
    navTo () {
      this.$router.replace({
        name: 'login',
        query: {
          identifier: this.identifier
        }
      })
    },
    async verifiedUser () {
      try {
        await Api().get('api/verified-user/' + this.$route.params.token)
        this.$store.dispatch('users/destroyCurrentUser')
        this.$store.dispatch('users/getCurrentUser')
      } catch (e) {
        this.navTo('verify-email')
      }
    }
  }
}
</script>

<style scoped>
.submitLogin {
    background-image: linear-gradient(270deg, #3ccdef 0%, #1c9edb 100%);
}
</style>
